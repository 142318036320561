<template>
    <div class="page_d MyAccount">




        <div class="pcShow">
            <div class="container center">
                <!-- <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon5.png" alt="img"
                    class="tel_icon2"> -->
                <div class="top_ds">
                    <div class="top_d_L">

                        <div class="name">
                            <div class="Boss_Name">
                                <div class="userName ellipsis2 pointer" @click="goAccount()">{{
                                    clientInfo.clientUserDTO ?
                                        clientInfo.clientUserDTO?.userName + ' ' + clientInfo.clientUserDTO?.userSurName :
                                        $t("message.NoDataAvailable") }}</div>
                                <div class="type" v-if="clientInfo.verifyStatus == '01'">{{ $t("message.Owner") }}</div>
                                <div class="type" v-else-if="clientInfo.clientUserDTO?.userRole == '01'">
                                    {{ $t("message.Owner") }}</div>
                                <div class="type" v-else-if="clientInfo.clientUserDTO?.userRole == '02'">
                                    {{ $t("message.Manager") }}</div>
                                <div class="type" v-else-if="clientInfo.clientUserDTO?.userRole == '03'">
                                    {{ $t("message.Employee") }}</div>
                                <span class="flex1"></span>
                                <!-- <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/Frame.png"
                                    v-if="clientInfo.verifyStatus == '02' && clientInfo.clientUserDTO?.userRole == '01'"
                                    @click="copyText()" alt="img" class="add_img pointer"> -->
                                <div class="add_img pointer"
                                    v-if="clientInfo.verifyStatus == '02' && clientInfo.clientUserDTO?.userRole == '01'"
                                    @click="copyText()">
                                    <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/Frame.png"
                                        alt="img">
                                    {{ $t("message.AddEmployee") }}
                                </div>
                            </div>

                        </div>
                        <div class="detailAddress">{{ clientInfo.detailAddress }}</div>
                        <span class="tel">{{ $t("message.Tel") }}: {{
                            clientInfo.clientUserDTO?.userMobile || $t("message.NoDataAvailable") }}</span>
                    </div>




                    <div class="top_d_R">
                        <div class="icon icon1"
                            v-if="activity_purchaseRecord.clientLevel == 1 && signed_verifySigned_contractStatus == 2">
                        </div>
                        <div class="icon icon2"
                            v-else-if="activity_purchaseRecord.clientLevel == 2 && signed_verifySigned_contractStatus == 2">
                        </div>
                        <div class="icon icon3"
                            v-else-if="activity_purchaseRecord.clientLevel == 3 && signed_verifySigned_contractStatus == 2">
                        </div>
                        <div><span class="icon_class icon_class1" v-if="clientInfo.verifyStatus == '02'">{{
                            clientInfo.identityName
                                }}</span>
                        </div>
                        <div> <span v-if="clientInfo.verifyStatus == '01'" class="icon_class icon_class2">{{
                            $t("message.eXtraWokrshopCandidate") }}</span>
                        </div>
                        <div v-if="clientInfo.verifyStatus == '01'" style="margin-top: 18px;"></div>
                        <div class="tel">{{ clientInfo.clientName }}</div>


                        <div class="tel_bom pointer" @click="layer = 2" v-if="signed_verifySigned_contractStatus == 2">
                            {{ $t("message.EnterBoschModuleTiering") }}</div>
                        <img @click="goModule()"
                            v-if="signed_verifySigned_contractStatus != 2 && clientInfo.verifyStatus == '02'"
                            style="margin-top: 0px;"
                            src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon25.png" alt="img"
                            class="bss_banner pointer">
                    </div>
                </div>
                <div class="nav_ds justify-content-start" v-if="clientInfo.verifyStatus == '02'">
                    <div class="nav_banner_d pointer" @click="gomember_index()" v-if="clientInfo.verifyStatus == '02'">
                        <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon26.png" alt="img"
                            class="nav_banner26">

                        <div class="nav_img_text1">{{ $t("message.RoleManagement") }}</div>
                        <div class="nav_img_text2">{{ $t("message.ViewDetails") }} ></div>
                        <div class="nav_img_text">{{ $t("message.CurrentMember") }}: {{ queryClientDetailLength }}</div>
                    </div>

                    <div class="nav_banner_d pointer" v-else @click="clare()"> <img
                            src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon26.png" alt="img"
                            class="nav_banner26">
                        <div class="nav_img_text1">{{ $t("message.RoleManagement") }}</div>
                        <div class="nav_img_text2">{{ $t("message.ViewDetails") }} ></div>
                        <div class="nav_img_text">{{ $t("message.YourRegistrationApplicationIsCurrentlyUnderReview") }}
                        </div>
                    </div>
                    <div class="nav_d tab_d">
                        <div class="tab_li pointer" @click="goWeb('https://extraloyaltyaps.page.link/app')">
                            <div class="tab_li_text1">{{ $t("message.EarnPointsEveryDay") }}</div>
                            <div class="tab_li_text2">{{ $t("message.eXtraPoints") }} <span>{{ clientInfo.bssPointValue
                                | LocaleString ||
                                0 }}</span>
                            </div>
                            <div class="tab_li_text3">
                                <div class="tab_li_text3_btn">{{ $t("message.Go") }}</div>
                                <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon8.png"
                                    alt="img" class="tab_img">
                            </div>

                        </div>
                        <div class="tab_d_border"></div>
                        <div class="tab_li pointer" @click="goWeb('https://extraloyaltyaps.page.link/app')">
                            <div class="tab_li_text1">{{ $t("message.eXtraMall") }}</div>
                            <div class="tab_li_text2 null">11</div>
                            <div class="tab_li_text3">
                                <div class="tab_li_text3_btn">{{ $t("message.Redeem") }}</div>
                                <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon9.png"
                                    alt="img" class="tab_img">
                            </div>

                        </div>

                    </div>

                </div>

                <div class="form-d">
                    <div class="container_title">
                        {{ $t("message.AccountData") }}
                    </div>
                    <div class="input_d pointer" @click="goAccount()">
                        <div class="input_L"><img
                                src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon16.png"
                                alt="img" class="input_L_icon"> {{ $t("message.PersonalData") }}</div>
                        <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img"
                            class="input_R_next">
                    </div>
                    <div class="input_d pointer" @click="goCompany()">
                        <div class="input_L"><img
                                src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon17.png"
                                alt="img" class="input_L_icon">{{ $t("message.CompanyData") }}</div>
                        <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img"
                            class="input_R_next">
                    </div>
                    <div class="input_d pointer" @click="gopersonalData_password()">
                        <div class="input_L"><img
                                src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon18.png"
                                alt="img" class="input_L_icon">{{ $t("message.ChangeCompanyPhoto") }}</div>
                        <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img"
                            class="input_R_next">
                    </div>

                </div>
                <div class="clare pointer" @click="clare()">{{ $t("message.LogOut") }}</div>
            </div>
        </div>
        <div class="ydShow bgf4" style="padding-bottom: 10px;">
            <div class="top_d">
                <div style="flex: 1;">
                    <div class="name">
                        <div class="userName ellipsis1 pointer" @click="goAccount()">{{
                            clientInfo.clientUserDTO ?
                                clientInfo.clientUserDTO?.userName + ' ' + clientInfo.clientUserDTO?.userSurName :
                                $t("message.NoDataAvailable") }}</div>


                    </div>
                    <div class="type" v-if="clientInfo.verifyStatus == '01'">{{ $t("message.Owner") }}</div>
                    <div class="type" v-else-if="clientInfo.clientUserDTO?.userRole == '01'">{{ $t("message.Owner")
                        }}
                    </div>
                    <div class="type" v-else-if="clientInfo.clientUserDTO?.userRole == '02'">
                        {{ $t("message.Manager") }}</div>
                    <div class="type" v-else-if="clientInfo.clientUserDTO?.userRole == '03'">
                        {{ $t("message.Employee") }}</div>


                    <div class="detailAddress">{{ clientInfo.detailAddress }}</div>
                    <span class="tel">{{ $t("message.Tel") }}:{{
                        clientInfo.clientUserDTO?.userMobile
                        || $t("message.NoDataAvailable") }}</span>
                </div>

                <div class="add_img pointer"
                    v-if="clientInfo.verifyStatus == '02' && clientInfo.clientUserDTO?.userRole == '01'"
                    @click="copyText()">
                    <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/Frame.png" alt="img">
                    {{ $t("message.AddEmployee") }}
                </div>
                <!-- <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/add.png" @click="copyText()"
                    v-if="clientInfo.verifyStatus == '02' && clientInfo.clientUserDTO?.userRole == '01'" alt="img"
                    class="add_img pointer"> -->
            </div>
            <!-- signed_verifySigned_contractStatus:{{signed_verifySigned_contractStatus}} -->
            <div class="container">

                <div class="row12 center_d">

                    <div class="icon icon1"
                        v-if="activity_purchaseRecord.clientLevel == 1 && signed_verifySigned_contractStatus == 2">
                    </div>
                    <div class="icon icon2"
                        v-else-if="activity_purchaseRecord.clientLevel == 2 && signed_verifySigned_contractStatus == 2">
                    </div>
                    <div class="icon icon3"
                        v-else-if="activity_purchaseRecord.clientLevel == 3 && signed_verifySigned_contractStatus == 2">
                    </div>
                    <span class="icon_class icon_class1" v-if="clientInfo.verifyStatus == '02'">{{
                        clientInfo.identityName
                    }}</span>


                    <span v-if="clientInfo.verifyStatus == '01'" class="icon_class icon_class2">{{
                        $t("message.eXtraWokrshopCandidate") }}</span>
                    <div class="name">{{ clientInfo.clientName }}</div>

                    <img @click="goModule()"
                        v-if="signed_verifySigned_contractStatus != 2 && clientInfo.verifyStatus == '02'"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon15.png" alt="img"
                        class="bss_banner pointer">
                    <div class="golevel pointer" @click="layer = 2" v-if="signed_verifySigned_contractStatus == 2">
                        {{ $t("message.EnterBoschModuleTiering") }}</div>
                </div>
            </div>
            <div class="container" v-if="clientInfo.verifyStatus == '02'">
                <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon5.png" alt="img"
                    class="tel_icon">
                <div class="row12 nav_d tab_d pointer" style="height: 97px;" @click="gomember_index()"
                    v-if="clientInfo.verifyStatus == '02'">
                    <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon26.png" alt="img"
                        class="nav_img_icon26">
                    <div class="nav_img_text1">{{ $t("message.RoleManagement") }}</div>
                    <div class="nav_img_text2">{{ $t("message.ViewDetails") }} ></div>
                    <div class="nav_img_text">{{ $t("message.CurrentMember") }}: {{ queryClientDetailLength }}</div>
                </div>
                <div class="row12 nav_d tab_d pointer" v-else style="height: 97px;" @click="clare()">
                    <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon26.png" alt="img"
                        class="nav_img_icon26">
                    <div class="nav_img_text1">{{ $t("message.RoleManagement") }}</div>
                    <div class="nav_img_text2">{{ $t("message.ViewDetails") }} ></div>
                    <div class="nav_img_text3">{{ $t("message.YourRegistrationApplicationIsCurrentlyUnderReview") }}
                    </div>
                </div>
            </div>
            <div class="container" v-if="clientInfo.verifyStatus == '02'">
                <div class="row12 nav_d tab_d" @click="goWeb('https://extraloyaltyaps.page.link/app')">
                    <div class="tab_li">
                        <div class="tab_li_text1">{{ $t("message.EarnPointsEveryDay") }}</div>
                        <div class="tab_li_text2">{{ $t("message.eXtraPoints") }} <span>{{ clientInfo.bssPointValue || 0
                                }}</span></div>
                        <div class="tab_li_text3">
                            <div class="tab_li_text3_btn">{{ $t("message.Redeem") }}</div>
                            <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon8.png"
                                alt="img" class="tab_img">
                        </div>
                    </div>
                    <div class="tab_d_border"></div>
                    <div class="tab_li">
                        <div class="tab_li_text1">{{ $t("message.eXtraMall") }}</div>
                        <div class="tab_li_text2 null">11</div>
                        <div class="tab_li_text3">
                            <div class="tab_li_text3_btn">{{ $t("message.Go") }}</div>
                            <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon9.png"
                                alt="img" class="tab_img">
                        </div>

                    </div>

                </div>
            </div>
            <div class="container">

                <div class="row12 form-d">
                    <div class="container_title">
                        {{ $t("message.AccountData") }}
                    </div>
                    <div class="input_d pointer" @click="goAccount()">
                        <div class="input_L"><img
                                src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon16.png"
                                alt="img" class="input_L_icon">{{ $t("message.PersonalData") }}</div>
                        <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img"
                            class="input_R_next">
                    </div>
                    <div class="input_d pointer" @click="goCompany()">
                        <div class="input_L"><img
                                src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon17.png"
                                alt="img" class="input_L_icon">{{ $t("message.CompanyData") }}</div>
                        <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img"
                            class="input_R_next">
                    </div>
                    <div class="input_d pointer" @click="gopersonalData_password()">
                        <div class="input_L"><img
                                src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/icon18.png"
                                alt="img" class="input_L_icon">{{ $t("message.ChangeCompanyPhoto") }}</div>
                        <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img"
                            class="input_R_next">
                    </div>

                </div>

            </div>
            <div class="clare pointer" @click="clare()">{{ $t("message.LogOut") }}</div>
        </div>
        <div class="layer_bg" v-if="layer == 1">
            <div class="layer">
                <img class="layer_img" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/1_layer.png" alt=""
                    srcset="">

            </div>
            <img class="layer_close pointer" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/close.png"
                @click="layer = 0">
        </div>
        <div class="layer_bg" v-if="layer == 2">
            <div class="layer">
                <img class="layer_img"
                    :src="'https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/clientLevel/' + clientInfo.countryName + '-' + activity_purchaseRecord.clientLevel + '.png'"
                    alt="" srcset="">
                <!-- <img class="layer_img" :src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/BSSList/2_layer.png"
                    v-if="activity_purchaseRecord.clientLevel == 2" alt="" srcset="">
                <img class="layer_img" :src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/BSSList/3_layer.png"
                    v-if="activity_purchaseRecord.clientLevel == 3" alt="" srcset=""> -->

            </div>
            <img class="layer_close pointer" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/close.png"
                @click="layer = 0">
        </div>


    </div>
</template>

<script>
export default {

    components: {
    },
    name: "MyAccount",
    props: {
        msg: String,
    },
    data() {
        return {
            clientInfo: '',
            layer: 0,
            signed_verifySigned_contractStatus: '',
            activity_purchaseRecord: '',
            queryClientDetailLength: 0,

        }
    },
    created() {
        if (localStorage.getItem("client_info")) {
            // this.clientInfo = JSON.parse(localStorage.getItem("client_info"))
            // console.log('clientInfo==', this.clientInfo)
        } else {
            var that = this
            that.$router.push({
                name: "login_index",
            });

        }
        if (localStorage.getItem("clientToken")) {
            this.getsigned_verifySigned()




        }
    },
    methods: {
        goWeb(url) {
            var Mobile = this.checkMobile()
            console.log('Mobile==', Mobile)
            if (Mobile) {
                window.open(url, "_blank");
            } else {
                this.layer = 1
            }


        },
        goAccount() {
            var that = this
            that.$router.push({
                name: "personalData_keyInformation",
            }, () => { });
        },
        goCompany() {
            var that = this
            that.$router.push({
                name: "personalData_basicInformation",
            }, () => { });
        },
        gopersonalData_password() {
            var that = this
            that.$router.push({
                name: "personalData_password",
            }, () => { });
        },
        copyText() {
            var that = this
            that.$router.push({
                name: "add_Employee",
            });
        },
        getqueryClientDetail() {
            var that = this
            that.$flyNo.request({
                method: 'GET',
                url: that.httpUrl.client_userapply_allList,
                body: {
                }
            }).then(res => {
                if (!res) return false
                that.queryClientDetailLength = res.resultData.clientApplyAllList.length
            })
        },
        clare() {
            var that = this
            that.$router.push({
                name: "login_index",
            });

        },
        getclient_info() {
            var that = this
            that.$flyNo.request({
                method: 'GET',
                url: that.httpUrl.client_info,
                body: {
                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.client_info, '_res==', res)
                // res.resultData.clientInfo.verifyStatus = '01' ////////////////ceshi
                that.clientInfo = res.resultData.clientInfo
                localStorage.setItem("client_info", JSON.stringify(res.resultData.clientInfo))
                console.log('clientInfo______________', that.clientInfo)

                if (that.clientInfo.verifyStatus == '02') {

                    this.getbsss_activity_purchaseRecord()
                    this.getqueryClientDetail()
                }

            })
        },
        getbsss_activity_purchaseRecord() {
            var that = this
            that.$flyNo.request({
                method: 'GET',
                url: that.httpUrl.bsss_activity_purchaseRecord,
                body: {

                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.bsss_activity_purchaseRecord, '_res==', res.resultData)
                that.activity_purchaseRecord = res.resultData
                console.log('that.activity_purchaseRecord==', that.activity_purchaseRecord)

            })
        },
        getsigned_verifySigned() {
            var that = this
            that.$flyNo.request({
                method: 'GET',
                url: that.httpUrl.signed_verifySigned,
                body: {
                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.signed_verifySigned, '_res==', res)
                // 0未申请，1审核中，2审核通过
                that.signed_verifySigned_contractStatus = res.resultData.contractStatus
                localStorage.setItem("signed_verifySigned_contractStatus", res.resultData.contractStatus)
                that.getclient_info()
            })
        },
        goModule() {
            var that = this
            if (!localStorage.getItem("client_info")) {
                that.$router.push({
                    name: "Module"
                }, () => {
                    // that.$nextTick(() => {
                    //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                    // })
                })
                return false
            }
            that.$flyNo.request({
                method: 'GET',
                url: that.httpUrl.signed_verifySigned,
                body: {
                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.signed_verifySigned, '_res==', res)
                // 0未申请，1审核中，2审核通过
                localStorage.setItem("signed_verifySigned_contractStatus", res.resultData.contractStatus)
                if (localStorage.getItem("signed_verifySigned_contractStatus") == 0) {
                    that.$router.push({
                        name: "Module"
                    }, () => { })

                    return false
                } else {
                    that.$router.push({
                        name: "BSSList"
                    }, () => { })
                }

            })



        },
        gologin_rule_list() {
            var that = this
            this.$router.push({
                name: "login_rule_list",
                query: { type: 2 }
            }, () => { });
        },
        goactivityRecord() {
            var that = this
            that.$router.push({
                name: "activityRecord"
            }, () => { })
        },
        gomember_index() {
            var that = this
            that.$router.push({
                name: "member_index"
            }, () => { })
        },
    }
}
</script>
<style lang="scss" scoped>
.form-d {
    margin-top: 0px;
    background: #fff;
    padding: 1px 16px 10px 16px;
    box-sizing: border-box;
    border-radius: 5px;

}

.layer {
    background: none;
}

.login_no {
    color: #007BC0;
    font-size: 12px;
}

.clare {
    font-size: 15px;
    color: #007BC0;
    margin: 33px auto 60px;
    width: 100%;
    text-align: center;
}

.nav_banner_d {
    width: calc(50% - 10px);
    min-height: 121px;
    position: relative;
    margin-right: 15px;
}

.golevel {
    font-size: 12px;
    color: #007BC0;
    margin-top: 10px;

}

.bom_img {
    width: 23px;
    height: auto;
    margin-bottom: 6px;
}

.title {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
}

.bom_d {
    font-size: 12px;
    color: #333333;
    margin-top: 23px;
    display: flex;
    line-height: 15px;
    justify-content: space-between;
    padding: 0 13px
}

.null {
    opacity: 0;
}

.tab_li_text1 {
    font-size: 13px;
    color: #000000;
}

.tab_li_text2 {
    flex: 1;
    font-size: 12px;
    color: #A2A2A2;
}

.tab_li_text2 span {
    color: #EB5336;
    font-size: 14px;
}

.tab_li_text3 {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab_li_text3_btn {
    border: 1px solid #007BC0;
    font-size: 12px;
    // padding: 5px 25px;
    box-sizing: border-box;
    line-height: 1;
    min-width: 66px;
    padding: 0 10px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.tab_d_border {
    width: 1px;
    height: 21px;
    background: #E8F1FF;
    margin: 0 18px;
}

.tab_d {
    background: #fff;
    padding: 16px 14px;
    box-sizing: border-box;
    border-radius: 5px;
    align-items: center;

}

.tab_li {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tab_img {
    width: 28px;
    height: 28px;
    margin-right: 30px;
}

.nav_d {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    position: relative
}

.nav_img {
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: 5px;
}

.nav_img_text1 {
    position: absolute;
    left: 14px;
    top: 14px;
    font-size: 16px;
    color: #000000;
}

.nav_img_text2 {
    position: absolute;
    right: 14px;
    top: 14px;
    font-size: 12px;
    color: #007BC0;
}

.nav_img_text3 {
    width: 249px;

    position: absolute;
    left: 14px;
    top: 48px;
    font-size: 14px;
    color: #71767C;
}

.nav_img_text {
    position: absolute;
    left: 14px;
    bottom: 14px;
    font-size: 14px;
    color: #71767C;
}

.tel_icon {
    width: 44px;
    height: 44px;
    position: fixed;
    top: 30vh;
    right: 0;
    z-index: 99;
}

.dropdown {
    color: #007BC0;
    font-size: 12px;
}

.el-icon-arrow-down:before {
    color: #007BC0;
}


.icon_class {
    width: auto;
    height: 20px;
    padding: 3px 18px;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;


}

.icon_class1 {
    background: #182C55;
}

.icon_class2 {
    background: #288090;
}

.icon {
    position: absolute;
    top: 0;
    right: 18px;
}

.icon1 {
    width: 83px;
    height: 40.5px;
    background-image: url(https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/1.png);
    background-position: center 0rpx;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.icon2 {
    width: 83px;
    height: 40.5px;
    background-image: url(https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/2.png);
    background-position: center 0rpx;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.icon3 {
    width: 83px;
    height: 40.5px;
    background-image: url(https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/MyAccount/3.png);
    background-position: center 0rpx;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.center_d {
    background: linear-gradient(111.45deg, #FFFFFF 7.75%, #EFFDFF 99.63%);
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;
    padding: 13px;
    box-sizing: border-box;

    .name {
        color: #000000;
        font-size: 14px;
        font-weight: bold;
        margin-top: 14px;
        line-height: 1;
        margin-bottom: 8px;
    }

    .bss_banner {
        width: 100%;
        height: auto;
        margin-top: 8px;
    }

}

.top_d {
    background: #007BC0;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    // height: 82px;
    padding: 17px 20px 14px 20px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    box-sizing: border-box;

    .name {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .add_img {
        padding: 0 11px;
        height: 31px;
        font-size: 12px;
        background: #fff;
        color: #007BC0;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        margin-top: 4px;

    }

    .add_img img {
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }

    .type {
        box-sizing: border-box;
        margin-top: 7px;
        margin-bottom: 9px;
        line-height: 20px;
        display: inline-block;
        padding: 0 8px;
        background: rgba(255, 255, 255, .2);
        font-size: 12px;
        border-radius: 4px;
        font-weight: initial;
    }
    .detailAddress{
        font-size: 12px;
        margin-bottom: 13px;
}
    .tel {
        flex: 1;
        font-size: 13px;
        text-align: left;
    }


}

.userName {
    width: initial;
    max-width: 232px;
}

.nav_img_icon26 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 54px;
    height: 43px;
}

/* 移动端 */
@media (max-width: 1200px) and (min-width: 300px) {

    .tab_li_text3 {
        margin-top: 0px;
    }

    .userName {
        max-width: 163px;
        width: initial;
    }

}


.pcShow .center {
    margin-bottom: 100px;
    margin-top: 20px;
    position: relative;
    background: #F4F6F8;
    padding: 25px 22px;
    box-sizing: border-box;

    .form-d {
        width: calc(100% - 10px);
        margin-top: 20px;

    }

    .nav_banner_d {
        width: 562px;
        margin-right: 20px;
        height: 100%;
        background: #fff;
        border-radius: 5px;

    }

    .nav_ds {
        margin-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .nav_banner {
            width: 100%;
            height: auto;
            // height: 121px;
            // width: auto;
        }
    }

    .nav_banner26 {
        width: 76px;
        height: 60px;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .tel_icon2 {
        position: absolute;
        width: 44px;
        height: 44px;
        top: 30vh;
        right: 0;
        z-index: 99;
    }

    .tab_d {
        width: 562px;
        min-height: 121px;
        margin-bottom: 0;
    }

    .top_ds {
        display: flex;
        align-items: center;
    }

    .top_d_L {
        width: 562px;
        height: 176px;
        background: #007BC0;
        border-radius: 5px;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 29px 20px;
        box-sizing: border-box;

        .name {}

        .Boss_Name {
            font-size: 22px;
            margin-bottom: 9px;
            display: flex;
            align-items: flex-start;
        }

        .type {
            font-size: 12px;
            padding: 2px 8px;
            border-radius: 4px;
            background: rgba(255, 255, 255, .4);
            margin-left: 6px;
            
        }

        .add_img {
            padding: 0 11px;
            height: 31px;
            font-size: 12px;
            background: #fff;
            color: #007BC0;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;

        }
        .detailAddress{
            flex: 1;
            font-size: 14px;
        }
        .add_img img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
        }
    }

    .top_d_R {
        position: relative;
        border-radius: 5px;
        margin-left: 20px;
        width: 562px;
        height: 176px;
        padding: 19px 17px;
        box-sizing: border-box;
        border: 0.8px solid rgba(216, 230, 238, 1);
        background: linear-gradient(111.45deg, #FFFFFF 7.75%, #EFFDFF 99.63%);
        display: flex;
        flex-direction: column;

        .tel {
            font-size: 18px;
            color: #000;
            font-weight: bold;
            margin-bottom: 10px;
            margin-top: 6px;
        }

        .bss_banner {
            width: 100%
        }

        .tel_bom {
            margin-top: 44px;
            font-size: 16px;
            color: #007BC0;

        }

    }

    .bom_ds {
        width: 100%;

    }

    .bom_d {
        justify-content: flex-start;
        width: auto;

    }

    .bom_li {
        margin-right: 29px;
        padding-bottom: 20px;
    }


}
</style>