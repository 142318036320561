<template>
    <div>

        <!--  -->
        <!-- pcShow -->
        <div class="footer style7  margin0 footer_pos_bom" v-if="pathName != 'index'">
            <div class="container">
                <div class="container-wapper">
                    <div class="row">
                        <div class="box-footer col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="dungar-custommenu default ">
                                <ul class="menu pcShow_menu">
                                    <li class="title pcShow">{{ $t("message.RobertBoschGmbH") }}</li>
                                    <li class="menu-item pointer" @click="gorule(3)">
                                        <div>{{ $t("message.CorporateInformation") }}</div>
                                    </li>
                                    <li class="menu-item pointer" @click="gorule(2)">
                                        <div>{{ $t("message.LegalNotice") }}</div>
                                    </li>
                                    <li class="menu-item pointer" @click="gorule(1)">
                                        <div>{{ $t("message.DataProtectionPolicy") }}</div>
                                    </li>
                                    <li data-show-privacy-settings>
                                        <a href data-show-privacy-settings class="menu-item pointer"> {{
            $t("message.PrivacySettings") }}</a>
                                    </li>
                                    <li class="menu-item pointer" @click="gocontactUs()">
                                        <div>{{ $t("message.ContactUs") }}</div>
                                    </li>
                                    <li class="title ydShow">{{ $t("message.RobertBoschGmbH") }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon16.png" alt="img" class="icon17 ydShow">
            <!-- <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon17.png" alt="img" class="icon17 pcShow"> -->
        </div>
        <!-- <div class="footer style7 ydShow"
            v-if="pathName != 'index'">
            <div class="container">
                <div class="container-wapper">
                    <div class="row">

                        <div class="box-footer col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="dungar-custommenu default">
                                <ul class="menu">
                                    <li class="menu-item pointer" @click="gorule(3)">
                                        <div>{{ $t("message.CorporateInformation") }}</div>
                                    </li>
                                    <li class="menu-item pointer" @click="gorule(2)">
                                        <div>{{ $t("message.LegalNotice") }}</div>
                                    </li>
                                    <li class="menu-item pointer" @click="gorule(1)">
                                        <div>{{ $t("message.DataProtectionPolicy") }}</div>
                                    </li>
                                    <li class="menu-item pointer" >
                                        <a href="javaScript:void(0);" data-show-privacy-settings>{{ $t("message.PrivacySettings") }}</a>
                                    </li>
                                    <li class="title">{{ $t("message.RobertBoschGmbH") }}</li>
                                </ul>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
            <img v-if="nav == 0" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon16.png" alt="img"
                class="icon17 ydShow">
        </div> -->

        <div class="food_btn_H ydShow" v-if="food_btn_H"></div>
        <div class="ydShow" style="height: 73px;" v-if="nav > 0 && pathName != 'index'"></div>
        <div class="footer-device-mobile ydShow" v-if="nav > 0 && pathName != 'index'">
            <div class="wapper">
                <div class="footer-device-mobile-item device-home pointer">
                    <div :class="nav == 1 ? 'active' : ''" @click="gohome()">
                        <img v-if="nav == 1" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/tab/1.png"
                            alt="img" class="icon">
                        <img v-if="nav != 1" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/tab/1_no.png"
                            alt="img" class="icon">
                        {{ $t("message.Home") }}
                    </div>
                </div>
                <div class="footer-device-mobile-item device-home device-wishlist pointer">
                    <div :class="nav == 2 ? 'active' : ''" @click="goWhatsOn()">
                        <img v-if="nav == 2" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/tab/2.png"
                            alt="img" class="icon">
                        <img v-if="nav != 2" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/tab/2_no.png"
                            alt="img" class="icon">
                        {{ $t("message.WhatSon") }}
                    </div>
                </div>
                <div class="footer-device-mobile-item device-home device-cart pointer" @click="goAuto_tech()">
                    <div :class="nav == 3 ? 'active' : ''">
                        <img v-if="nav == 3" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/tab/3.png"
                            alt="img" class="icon">
                        <img v-if="nav != 3" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/tab/3_no.png"
                            alt="img" class="icon">
                        {{ $t("message.TechWiki") }}
                    </div>
                </div>
                <div class="footer-device-mobile-item device-home device-user pointer" @click="goModule()">
                    <div :class="nav == 4 ? 'active' : ''">
                        <img v-if="nav == 4" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/tab/4.png"
                            alt="img" class="icon">
                        <img v-if="nav != 4" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/tab/4_no.png"
                            alt="img" class="icon">
                        {{ $t("message.Module") }}
                    </div>
                </div>
                <div class="footer-device-mobile-item device-home device-user pointer" @click="goMyAccount()">
                    <div :class="nav == 5 ? 'active' : ''">
                        <img v-if="nav == 5" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/tab/5.png"
                            alt="img" class="icon">
                        <img v-if="nav != 5" src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/tab/5_no.png"
                            alt="img" class="icon">
                        {{ $t("message.MyAccount") }}
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { EventBus } from "../main"
export default {
    name: 'Footer',
    props: {
        msg: String
    },
    data() {
        return {
            food_btn_H: false,
            nav: 1,
            clientInfo: '',
            pathName: '',
        }
    },
    watch: {
        $route(to, from, next) {
            var that = this
            // console.log('1111to.path==', to.name)
            // that.food_btn_H = this.checkIfClassExists('food_btns');
            if (document.querySelector('.food_btns')) {
                console.log('元素存在');
                this.food_btn_H = true
            } else {
                console.log('元素不存在');
                this.food_btn_H = false
            }
        },
    },
    created() {
        var that = this
        EventBus.$on('nav', (newValue) => {
            that.pathName = ''
            // 处理全局变量变化
            // console.log('nav=====', newValue);
            if (localStorage.getItem("client_info")) {
                that.clientInfo = JSON.parse(localStorage.getItem("client_info"))
            }
            that.country = JSON.parse(localStorage.getItem("country")) || ''
            that.nav = newValue.nav
            const currentRoute = this.$router.currentRoute;
            this.pathName = currentRoute.name
            localStorage.setItem("nav", that.nav)
            // console.log('this.pathName==========', this.pathName)

        })

    },
    mounted() {
        var that = this
        that.country = localStorage.getItem("country") || ''
        const currentRoute = this.$router.currentRoute;
        this.pathName = currentRoute.name
        // console.log('name_________________________', this.pathName)
        // console.log('nav_________________________', this.nav)
    },
    methods: {
        handleClick(event) {
            // 在这里处理你的点击事件，链接不会跳转
            console.log('Link clicked!');
        },
        checkIfClassExists(className) {
            const elements = document.querySelectorAll('.' + className);
            if (elements.length > 0) {

                // console.log('页面中存在class元素:', className);
                return true
            } else {
                // console.log('页面中不存在class元素:', className);
                return false
            }
        },
        gocontactUs(){
            var that = this
            this.tab = 0
            that.$router.push({
                name: "contactUs"
            }, () => {
               
            })
        },
        gorule(type) {
            this.tab = 0
            if (!localStorage.getItem("country")) return
            this.$router.push({
                name: "login_rule_list",
                query: { type: type }
            }, () => {
                // that.$nextTick(() => {
                //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                // })
            });
        },
        gohome() {
            this.tab = 0
            var that = this
            this.nav = 1
            var countryNameAbbreviation = JSON.parse(localStorage.getItem("country")).countryNameAbbreviation
            this.$router.push({ name: 'Home', params: { myParam: countryNameAbbreviation } }, () => {
                // that.$nextTick(() => {
                //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                // })
            });
        },
        goWhatsOn() {
            // console.log('-------------------------------------------')
            this.tab = 0
            var that = this
            this.nav = 2
            that.$router.push({
                name: "WhatsOn"
            }, () => {
                // that.$nextTick(() => {
                //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                // })
            })
        },

        goRewards() {
            this.tab = 0
            var that = this
            this.nav = 3
            that.$router.push({
                name: "Rewards",
            }, () => {
                // that.$nextTick(() => {
                //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                // })
            })
        },
        goAuto_tech() {
            this.tab = 0
            var that = this
            this.nav = 3
            that.$router.push({
                name: "Auto_tech",
            }, () => {
                // that.$nextTick(() => {
                //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                // })
            })
        },
        goModule() {
            this.tab = 0
            var that = this
            this.nav = 4
            if(!localStorage.getItem("client_info")){
                that.$router.push({
                        name: "Module"
                    }, () => {
                        // that.$nextTick(() => {
                        //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                        // })
                    })
                    return false
            }
            that.$flyNo.request({
                method: 'GET',
                url: that.httpUrl.signed_verifySigned,
                body: {
                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.signed_verifySigned, '_res==', res)
                // 0未申请，1审核中，2审核通过
                localStorage.setItem("signed_verifySigned_contractStatus", res.resultData.contractStatus)

                // 0未申请，1审核中，2审核通过
                if (localStorage.getItem("signed_verifySigned_contractStatus") == 0 || !localStorage.getItem("clientToken")) {
                    that.$router.push({
                        name: "Module"
                    }, () => {
                        that.$nextTick(() => {
                            that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                        })
                    })
                    return false
                } else if (localStorage.getItem("signed_verifySigned_contractStatus") == 1) {
                    that.$router.push({
                        name: "BSSList"
                    }, () => {
                        that.$nextTick(() => {
                            that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                        })
                    })
                } else if (localStorage.getItem("signed_verifySigned_contractStatus") == 2) {
                    that.$router.push({
                        name: "BSSList"
                    }, () => {
                        that.$nextTick(() => {
                            that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                        })
                    })
                } else {
                    that.$router.push({
                        name: "Module"
                    }, () => {
                        // that.$nextTick(() => {
                        //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                        // })
                    })
                }


            })
        },
        goMyAccount() {
            var that = this

            if (localStorage.getItem("client_info")) {
                this.nav = 5
                that.$router.push({
                    name: "MyAccount",
                }, () => {
                    // that.$nextTick(() => {
                    //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                    // })
                })
            } else {
                that.$router.push({
                    name: "login_index",
                }, () => {
                    // that.$nextTick(() => {
                    //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                    // })

                });
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.title {
    font-weight: normal;
}

.icon17 {
    width: 100%;
    height: auto;
}

.footer {
    background-color: #fff;
    margin-bottom: 0;
    padding-bottom: 0;
}

.footer_pos_bom {
    /* position: fixed;
    bottom: 0; */
    width: 100%;
    z-index: 9999;
}

.pcShow_menu {
    margin-top: 10px;
    z-index: 9999;
}

@media (max-width: 1200px) and (min-width: 300px) {
    .footer {
        border-top: 1px solid #D9D9D9;
        padding-top: 25px;
    }

    .pcShow_menu {
        margin-top: 0px;
    }

    .pcShow_menu {
        flex-direction: column;
    }

    .footer .pcShow_menu .menu-item {
        margin-left: 0;
    }
}
</style>
