import Vue from "vue";

import Router from "vue-router";
import index from './views/index.vue'

Vue.use(Router);
var countryNameAbbreviation = ''
if(localStorage.getItem("country")){
    countryNameAbbreviation = '/'+JSON.parse(localStorage.getItem("country")).countryNameAbbreviation+'/'
}
export default new Router({
    mode: 'history', //上线


    // base: countryNameAbbreviation,//博世测试
    routes: [
        {
            path: '/',
            name: 'index',
            component: index,
            meta: {
                keepAlive: false // 首页
            }
        },
        {
            path: 'ceshi',
            name: 'ceshi',
            component: resolve => require(['@/views/ceshi.vue'], resolve),
            meta: {
                keepAlive: false //
            }
        },
        {
            // path: '/Home',
            name: 'Home',
            path: '/:myParam/Home',
            component: resolve => require(['@/views/Home.vue'], resolve),
            meta: {
                keepAlive: false //
            }
        },
        {
            path: '/:myParam/WhatsOn',
            name: 'WhatsOn',
            component: resolve => require(['@/views/WhatsOn.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        
        {
            path: '/:myParam/contactUs',
            name: 'contactUs',
            component: resolve => require(['@/views/contactUs.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/Auto_tech',
            name: 'Auto_tech',
            component: resolve => require(['@/views/Auto_tech.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/Auto_tech_msg',
            name: 'Auto_tech_msg',
            component: resolve => require(['@/views/Auto_tech_msg.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/video',
            name: 'video',
            component: resolve => require(['@/views/video.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/videoSao',
            name: 'videoSao',
            component: resolve => require(['@/views/videoSao.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },

        {
            path: '/:myParam/Rewards',
            name: 'Rewards',
            component: resolve => require(['@/views/Rewards.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/MyAccount',
            name: 'MyAccount',
            component: resolve => require(['@/views/MyAccount.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/personalData_index',
            name: 'personalData_index',
            component: resolve => require(['@/views/personalData/index.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/personalData_password',
            name: 'personalData_password',
            component: resolve => require(['@/views/personalData/password.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/personalData_basicInformation',
            name: 'personalData_basicInformation',
            component: resolve => require(['@/views/personalData/basicInformation.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/personalData_keyInformation',
            name: 'personalData_keyInformation',
            component: resolve => require(['@/views/personalData/keyInformation.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/personalData_account',
            name: 'personalData_account',
            component: resolve => require(['@/views/personalData/account.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/personalData_company',
            name: 'personalData_company',
            component: resolve => require(['@/views/personalData/company.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },

        {
            path: '/:myParam/login_index',
            name: 'login_index',
            component: resolve => require(['@/views/login/index.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/index_Account',
            name: 'index_Account',
            component: resolve => require(['@/views/login/index_Account.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/index_Account_Employee',
            name: 'index_Account_Employee',
            component: resolve => require(['@/views/login/index_Account_Employee.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/add_Employee',
            name: 'add_Employee',
            component: resolve => require(['@/views/login/add_Employee.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },

        {
            path: '/:myParam/index_Account_Boss1',
            name: 'index_Account_Boss1',
            component: resolve => require(['@/views/login/index_Account_Boss1.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/index_Account_Boss2',
            name: 'index_Account_Boss2',
            component: resolve => require(['@/views/login/index_Account_Boss2.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },

        {
            path: '/:myParam/login_rule_list',
            name: 'login_rule_list',
            component: resolve => require(['@/views/login/rule_list.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/shop_index',
            name: 'shop_index',
            component: resolve => require(['@/views/shop/index.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },




        {
            path: '/:myParam/BSSList',
            name: 'BSSList',
            component: resolve => require(['@/views/BSSList.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/Module',
            name: 'Module',
            component: resolve => require(['@/views/Module.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/Module_back',
            name: 'Module_back',
            component: resolve => require(['@/views/Module_back.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },

        {
            path: '/:myParam/activityRecord',
            name: 'activityRecord',
            component: resolve => require(['@/views/activityRecord.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/article',
            name: 'article',
            component: resolve => require(['@/views/article.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/recordItem',
            name: 'recordItem',
            component: resolve => require(['@/views/recordItem.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/Autoparts',
            name: 'Autoparts',
            component: resolve => require(['@/views/Autoparts.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            },
            exact: true
        },
        {
            path: '/blank',
            name: 'blank',
            component: resolve => require(['@/views/blank.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            },
            exact: true
        },

        {
            path: '/:myParam/Autoparts_Bosch',
            name: 'Autoparts_Bosch',
            component: resolve => require(['@/views/Autoparts_Bosch.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },

        {
            path: '/:myParam/member_index',
            name: 'member_index',
            component: resolve => require(['@/views/member/index.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },
        {
            path: '/:myParam/member_memerMsg',
            name: 'member_memerMsg',
            component: resolve => require(['@/views/member/memerMsg.vue'], resolve),
            meta: {
                keepAlive: false // 联系我们
            }
        },

    ],


});
